<template>
    <mdb-container fluid>
        <div>
            <h1 class="page-header border-bottom mx-2">
                Schedule Reports
                <span class="smaller text-muted pl2 d-block d-sm-none d-xs-none d-md-inline-block">Internal View</span>
                <mdb-icon icon="calendar-alt" size="1x" class="float-right text-muted" role="button" />
            </h1>
            <mdb-row class="pt-2">
                <mdb-btn color="info" @click="newModal = true"
                    >New<mdb-icon icon="plus" class="pl-2"></mdb-icon
                ></mdb-btn>
            </mdb-row>
            <mdb-row class="pt-2">
                <vue-good-table
                    class="w-100"
                    :rows="tableRows"
                    mode="remote"
                    :columns="tableColumns"
                    :search-options="{ enabled: true }"
                    :isLoading.sync="loading"
                    styleClass="vgt-table striped bordered"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    @on-search="searchTable"
                    :totalRows="totalRecords"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: this.limit,
                        position: 'bottom',
                        perPageDropdown: [10, 25, 50, 100],
                        dropdownAllowAll: false,
                        setCurrentPage: this.page,
                        nextLabel: 'next',
                        prevLabel: 'prev',
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                    }"
                >
                    <template slot="loadingContent"> <mdb-spinner></mdb-spinner> Loading data... </template>
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field == 'enable/disable'">
                            <a class="btn-sm btn-outline-info" icon="edit" @click="toggleEnable(props.row)">
                                <i
                                    :class="[
                                        props.row.sch_active_desc == 'Active'
                                            ? 'fas fa-toggle-on'
                                            : 'fas fa-toggle-off',
                                    ]"
                                ></i>
                            </a>
                        </div>
                        <div v-else-if="props.column.field == 'edit'">
                            <a class="btn-sm btn-outline-info" @click="editReport(props.row)"
                                ><mdb-icon icon="edit"
                            /></a>
                        </div>
                        <div v-else-if="props.column.field == 'delete'">
                            <a class="btn-sm btn-outline-danger" @click="deleteReport(props.row)"
                                ><mdb-icon icon="trash"
                            /></a>
                        </div>
                    </template>
                </vue-good-table>
            </mdb-row>
            <mdb-modal :show="newModal" @close="newModal = false">
                <mdb-modal-header>
                    <mdb-modal-title tag="h4" bold class="w-100"
                        ><mdb-icon icon="calendar-alt" class="mr-2" />Schedule a Report</mdb-modal-title
                    >
                </mdb-modal-header>
                <mdb-modal-body class="mx-3 grey-text">
                    <h5 class="font-weight-bold">Report Details</h5>
                    <mdb-input type="text" v-model="newScheduleName" label="Enter Schedule Name" />
                    <mdb-select search @getValue="getCompanyValue" :options="companyOptions" label="Select Company" />
                    <mdb-select
                        v-if="showAccounts"
                        search
                        multiple
                        @getValue="getAccountsValue"
                        :options="accountsOptions"
                        label="Select Account"
                    />
                    <mdb-select search @getValue="getReportValue" :options="reportsOptions" label="Select A Report" />
                    <mdb-select @getValue="getFormatsValue" :options="formats" label="Select Format" />
                    <mdb-select
                        @getValue="getFrequenciesValue"
                        :options="frequencies"
                        label="Select Delivery Frequency"
                    />
                    <mdb-select @getValue="getDeliveryDaysValue" :options="deliveryDays" label="Select Delivery Day" />
                    <mdb-select
                        @getValue="getDateRangeValue"
                        :options="reportDateRanges"
                        label="Select Report Date Range"
                    />
                    <h5 class="font-weight-bold">Report Owner</h5>
                    <mdb-select search @getValue="getOwnerValue" :options="ownerOptions" label="Owner" />
                    <h5 class="font-weight-bold">e-Mail Recipients</h5>
                    <mdb-select multiple selectAll search @getValue="getToValue" :options="toOptions" label="To:" />
                    <mdb-select multiple selectAll search @getValue="getCcValue" :options="ccOptions" label="Cc:" />
                    <h5 class="font-weight-bold">FTP Recipients</h5>
                    <mdb-select
                        search
                        @getValue="getCompanyFTPValue"
                        :options="companyFTPOptions"
                        label="Company FTP:"
                    />
                    <mdb-select
                        search
                        @getValue="getResellerFTPValue"
                        :options="resellerFTPOptions"
                        label="Reseller FTP:"
                    />
                    <mdb-input label="example/path" v-model="examplePathOne"></mdb-input>
                    <mdb-input label="example/path" v-model="examplePathTwo"></mdb-input>
                </mdb-modal-body>
                <mdb-modal-footer right>
                    <mdb-btn color="light" @click="newModal = false">Close</mdb-btn>
                    <mdb-btn color="primary" @click="addSchedule">Save Changes</mdb-btn>
                    <!--<mdb-btn v-else disabled color="primary" @click="addSchedule">Save Changes</mdb-btn>-->
                </mdb-modal-footer>
            </mdb-modal>
            <mdb-modal :show="editModal" @close="editModal = false">
                <mdb-modal-header>
                    <mdb-modal-title tag="h4" bold class="w-100"
                        ><mdb-icon icon="calendar-alt" class="mr-2" />Schedule a Report</mdb-modal-title
                    >
                </mdb-modal-header>
                <mdb-modal-body class="mx-3 grey-text">
                    <h5 class="font-weight-bold">Report Details</h5>
                    <mdb-input type="text" v-model="newScheduleName" label="Enter Schedule Name" />
                    <mdb-select search @getValue="getCompanyValue" :options="companyOptions" label="Select Company" />
                    <mdb-select
                        v-if="showAccounts"
                        search
                        multiple
                        @getValue="getAccountsValue"
                        :options="accountsOptions"
                        label="Select Account"
                    />
                    <mdb-select search @getValue="getReportValue" :options="reportsOptions" label="Select A Report" />
                    <mdb-select @getValue="getFormatsValue" :options="formats" label="Select Format" />
                    <mdb-select
                        @getValue="getFrequenciesValue"
                        :options="frequencies"
                        label="Select Delivery Frequency"
                    />
                    <mdb-select @getValue="getDeliveryDaysValue" :options="deliveryDays" label="Select Delivery Day" />
                    <mdb-select
                        @getValue="getDateRangeValue"
                        :options="reportDateRanges"
                        label="Select Report Date Range"
                    />
                    <h5 class="font-weight-bold">Report Owner</h5>
                    <mdb-select search @getValue="getOwnerValue" :options="ownerOptions" label="Owner" />
                    <h5 class="font-weight-bold">e-Mail Recipients</h5>
                    <mdb-select multiple selectAll search @getValue="getToValue" :options="toOptions" label="To:" />
                    <mdb-select multiple selectAll search @getValue="getCcValue" :options="ccOptions" label="Cc:" />
                    <h5 class="font-weight-bold">FTP Recipients</h5>
                    <mdb-select
                        search
                        @getValue="getCompanyFTPValue"
                        :options="companyFTPOptions"
                        label="Company FTP:"
                    />
                    <mdb-select
                        search
                        @getValue="getResellerFTPValue"
                        :options="resellerFTPOptions"
                        label="Reseller FTP:"
                    />
                    <mdb-input label="example/path" v-model="examplePathOne"></mdb-input>
                    <mdb-input label="example/path" v-model="examplePathTwo"></mdb-input>
                </mdb-modal-body>
                <mdb-modal-footer right>
                    <mdb-btn color="light" @click="newModal = false">Close</mdb-btn>
                    <mdb-btn color="primary" @click="editSchedule">Save Changes</mdb-btn>
                    <!--<mdb-btn v-else disabled color="primary" @click="addSchedule">Save Changes</mdb-btn>-->
                </mdb-modal-footer>
            </mdb-modal>
        </div>
    </mdb-container>
</template>
<script>
export default {
    name: "AdminScheduleReports",
    components: {},
    props: {
        userInfo: Object,
        isAuthenticated: Boolean,
        headers: Object,
    },
    data() {
        return {
            loading: false,
            tableCreated: false,
            profilesCreated: false,
            companiesCreated: false,
            reportsCreated: false,
            resellerFTPcreated: false,
            companyFTPcreated: false,
            tableRows: [],
            tableColumns: [],
            table: null,
            userProfiles: null,
            companies: null,
            reports: null,
            resellerCredentials: null,
            companyCredentials: null,
            newModal: false,
            newScheduleName: "",
            selectedCompany: null,
            selectedReport: null,
            generalReports: [],
            customReports: [],
            systemReports: [],
            selectedFormat: null,
            selectedFrequency: null,
            selectedReportDateRange: null,
            selectedDeliveryDay: null,
            selectedReportOwner: null,
            selectedTo: [],
            selectedCc: "",
            selectedCompanyFTP: null,
            selectedResellerFTP: null,
            resellerFTPinput: "",
            userProfileOptions: [],
            dropdownVisible: false,
            toOptions: [],
            ccOptions: [],
            companyOptions: [],
            ownerOptions: [],
            companyFTPOptions: [],
            resellerFTPOptions: [],
            examplePathOne: "",
            examplePathTwo: "",
            generalReportsOptions: [],
            customReportsOptions: [],
            systemReportsOptions: [],
            reportsOptions: [],
            accountsResponse: null,
            accountsOptions: [],
            showAccounts: false,
            selectedAccounts: null,
            reportResponse: null,
            reportTable: null,
            accountsChildren: "",
            accountsValues: [],
            editModal: false,
            accountIds: [],
            editName: null,
            editId: null,
            verified: true,
            toDanger: false,
            scheduleDeleted: null,
            selectedCompanyftp: null,
            selectedResellerftp: null,
            rowToggled: null,
            totalRecords: null,
            limit: 10,
            size: 10,
            page: 1,
            sortColumn: 'sch_name',
            sortkey: 'sch_name',
            sortDir: 'asc',
            formats: [
                { value: "pdf", text: "PDF", selected: false },
                { value: "xls", text: "Excel", selected: false },
                { value: "csv", text: "CSV", selected: false },
                { value: "csn", text: "CSV (Not Quoted)", selected: false },
                { value: "xml", text: "XML", selected: false },
                { value: "edi", text: "EDI", selected: false },
            ],
            frequencies: [
                { value: "1", text: "Weekly", selected: false },
                { value: "2", text: "First Week", selected: false },
                { value: "3", text: "Second Week", selected: false },
                { value: "4", text: "Third Week", selected: false },
                { value: "5", text: "Fourth Week", selected: false },
                { value: "6", text: "Last Week", selected: false },
            ],
            deliveryDays: [
                { value: "2", text: "Tuesday", selected: false },
                { value: "3", text: "Wednesday", selected: false },
                { value: "4", text: "Thursday", selected: false },
                { value: "5", text: "Friday", selected: false },
                { value: "6", text: "Saturday", selected: false },
            ],
            reportDateRanges: [
                { value: "1", text: "Last Invoice Cycle", selected: false },
                { value: "2", text: "Last 14 Days", selected: false },
                { value: "3", text: "Last 30 Days", selected: false },
                { value: "4", text: "Last 60 Days", selected: false },
                { value: "5", text: "Last 90 Days", selected: false },
                { value: "6", text: "Last 4 Months", selected: false },
                { value: "7", text: "Last 6 Months", selected: false },
                { value: "8", text: "Last 9 Months", selected: false },
                { value: "9", text: "Last 1 Year", selected: false },
                { value: "10", text: "Last 18 Months", selected: false },
                { value: "11", text: "Last 2 Years", selected: false },
            ],
        };
    },
    methods: {
        async getInit() {
            this.tableCreated = await this.createTable();
            this.profilesCreated = await this.getUserProfiles();
            this.companiesCreated = await this.getCompanies();
            this.reportsCreated = await this.getReports();
            this.resellerFTPcreated = await this.getResellerFtpCredentials();
            this.companyFTPcreated = await this.getCompanyFtpCredentials();
        },
        async createTable() {
            this.loading = true;
            await this.$httpReports
                .get("/scheduledreport", {
                    headers: this.headers,
                    params: this.buildParams(),
                })
                .then(async (response) => {
                    this.loading = false;
                    this.totalRecords = response.data[0].TotalRecords;
                    this.table = response.data[0].Items;
                    this.tableRows = this.table;
                    this.tableColumns = [
                        { field: "sch_name", label: "Name", sort: true },
                        { field: "report_name", label: "Report", sort: true },
                        { field: "sch_freq_week_name", label: "Frequency", sort: true },
                        { field: "sch_freq_day_name", label: "Frequency", sort: true },
                        { field: "sch_timespan_name", label: "Time Span", sort: true },
                        { field: "sch_output_type", label: "Output Type", sort: true },
                        { field: "sch_active_desc", label: "Status", sort: true },
                        { field: "sch_to", label: "To", sort: true },
                        { field: "sch_cc", label: "CC", sort: true },
                        { field: "enable/disable", label: "Enable/Disable", sort: false },
                        { field: "edit", label: "Edit", sort: false },
                        { field: "delete", label: "Delete", sort: false },
                    ];
                });
            return true;
        },
        buildParams() {
            return {
                sch_creator_usr_id: this.searchTerm ? this.searchTerm : this.userInfo.usrId,
                sch_type: 1,
                limit: this.limit,
                size: this.size,
                page: this.page,
                sortColumn: this.sortColumn,
                sortkey: this.sortkey,
                sortDir: this.sortDir,
            };
        },
        async getUserProfiles() {
            await this.$http
                .get("/userprofile", {
                    headers: this.headers,
                    params: { usr_type: true },
                })
                .then(async (response) => {
                    this.userProfiles = response.data;
                    for (let i = 0; i < this.userProfiles.length; i++) {
                        this.toOptions.push({
                            value: i,
                            text:
                                this.userProfiles[i].usr_first_name +
                                " " +
                                this.userProfiles[i].usr_last_name +
                                " (" +
                                this.userProfiles[i].usr_email +
                                ")",
                            email: this.userProfiles[i].usr_email,
                            selected: false,
                        });
                        this.ccOptions.push({
                            value: i,
                            text:
                                this.userProfiles[i].usr_first_name +
                                " " +
                                this.userProfiles[i].usr_last_name +
                                " (" +
                                this.userProfiles[i].usr_email +
                                ")",
                            email: this.userProfiles[i].usr_email,
                            selected: false,
                        });
                        this.ownerOptions.push({
                            value: i,
                            text:
                                this.userProfiles[i].usr_first_name +
                                " " +
                                this.userProfiles[i].usr_last_name +
                                " (" +
                                this.userProfiles[i].usr_email +
                                ")",
                            email: this.userProfiles[i].usr_email,
                            selected: false,
                        });
                    }
                });
            return true;
        },
        async getCompanies() {
            await this.$httpAdmin.get("/company", { headers: this.headers }).then(async (response) => {
                this.companies = response.data;
                for (let i = 0; i < this.userProfiles.length; i++) {
                    this.companyOptions.push({
                        value: i,
                        text: this.companies[i].cmp_name + " (ID:" + this.companies[i].cmp_id + ")",
                        id: this.companies[i].cmp_id,
                        selected: false,
                    });
                }
            });
            return true;
        },
        async getReports() {
            await this.$httpReports.get("/report", { headers: this.headers }).then(async (response) => {
                this.reports = response.data;
                let count = 0;
                for (let i = 0; i < this.reports.length; i++) {
                    if (this.reports[i].type === 2) {
                        this.generalReports.push(this.reports[i]);
                    } else if (this.reports[i].type === 3) {
                        this.customReports.push(this.reports[i]);
                    } else {
                        this.systemReports.push(this.reports[i]);
                    }
                }
                this.reportsOptions.push({ value: null, text: "General Reports", disable: true, optgroup: true });
                for (let i = 0; i < this.generalReports.length; i++) {
                    this.reportsOptions.push({
                        value: this.generalReports[i].name,
                        text: this.generalReports[i].name,
                        selected: false,
                    });
                }
                this.reportsOptions.push({ value: null, text: "Custom Reports", disable: true, optgroup: true });
                for (let i = 0; i < this.customReports.length; i++) {
                    this.reportsOptions.push({
                        value: this.customReports[i].name,
                        text: this.customReports[i].name,
                        selected: false,
                    });
                }
                this.reportsOptions.push({ value: null, text: "System Reports", disable: true, optgroup: true });
                for (let i = 0; i < count + this.systemReports.length; i++) {
                    this.reportsOptions.push({
                        value: this.systemReports[i].name,
                        text: this.systemReports[i].name,
                        selected: false,
                    });
                }
            });
            return true;
        },
        async getResellerFtpCredentials() {
            await this.$httpAdmin.get("/resellerftpcredential", { headers: this.headers }).then(async (response) => {
                this.resellerCredentials = response.data;
                for (let i = 0; i < this.resellerCredentials.length; i++) {
                    this.resellerFTPOptions.push({
                        value: i,
                        text:
                            this.resellerCredentials[i].rfc_type +
                            "://" +
                            this.resellerCredentials[i].rfc_username +
                            "@" +
                            this.resellerCredentials[i].rfc_host +
                            " (ID: " +
                            this.resellerCredentials[i].rfc_id +
                            ")",
                        id: this.resellerCredentials[i].rfc_id,
                        selected: false,
                    });
                }
            });
            return true;
        },
        async getCompanyFtpCredentials() {
            await this.$httpAdmin.get("/companyftpcredential", { headers: this.headers }).then(async (response) => {
                this.companyCredentials = response.data;
                for (let i = 0; i < this.companyCredentials.length; i++) {
                    this.companyFTPOptions.push({
                        value: i,
                        text:
                            this.companyCredentials[i].cfc_type +
                            "://" +
                            this.companyCredentials[i].cfc_username +
                            "@" +
                            this.companyCredentials[i].cfc_host +
                            " (ID: " +
                            this.companyCredentials[i].cfc_id +
                            ")",
                        id: this.companyCredentials[i].cfc_id,
                        selected: false,
                    });
                }
            });
            return true;
        },
        verifyFields() {
            let isGood = true;
            if (this.newScheduleName == null || this.newScheduleName == "") {
                this.$notify.error({
                    message: "Please enter a name.",
                    title: "Name Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            if (this.selectedReport == null) {
                this.$notify.error({
                    message: "Please select a report.",
                    title: "Report Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            if (this.selectedFormat == null) {
                this.$notify.error({
                    message: "Please select an output format.",
                    title: "Format Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            if (this.selectedFrequency == null) {
                this.$notify.error({
                    message: "Please select a frequency.",
                    title: "Frequency Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            if (this.selectedReportDateRange == null) {
                this.$notify.error({
                    message: "Please select a timespan.",
                    title: "Conditions Field Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            if (
                (this.selectedTo == null || this.selectedTo.length == 0) &&
                this.selectedCompanyFTP == null &&
                this.selectedResellerFTP == null
            ) {
                this.$notify.error({
                    message: "Please select a recipient (e-mail or FTP).",
                    title: "Recipient Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            if (this.selectedReportOwner == null || this.selectedReportOwner.length == 0) {
                this.$notify.error({
                    message: "Please select a report owner.",
                    title: "Owner Error",
                    timeOut: 5000,
                    variant: "danger",
                });
                isGood = false;
            }
            return { isGood };
        },
        addSchedule() {
            this.verified = this.verifyFields();
            if (this.verified) {
                this.$httpReports
                    .post("/scheduledreport", this.reportParams(), { headers: this.headers })
                    .then(async (response) => {
                        this.reportResponse = response;
                        this.newModal = false;
                        await this.createTable();
                        this.newScheduleName = "";
                    });
            }
        },
        reportParams() {
            if (this.selectedCompanyFTP == null) {
                this.selectedCompanyftp = -1;
            } else {
                this.selectedCompanyftp = this.selectedCompanyFTP.cfc_id;
            }
            if (this.selectedResellerFTP == null) {
                this.selectedResellerftp = -1;
            } else {
                this.selectedResellerftp = this.selectedResellerFTP.rfc_id;
            }
            return {
                sch_cmp_id: this.selectedCompany.cmp_id,
                sch_report_id: this.selectedReport.id,
                sch_name: this.newScheduleName,
                sch_output_type: this.selectedFormat,
                sch_freq_week: this.selectedFrequency,
                sch_freq_day: this.selectedDeliveryDay,
                sch_timespan: this.selectedReportDateRange,
                sch_accounts: this.accountsChildren,
                sch_to: this.selectedTo,
                sch_cc: this.selectedCc,
                sch_owner_usr_id: this.selectedReportOwner,
                sch_type: 1,
                sch_cmp_ftp_credential_id: this.selectedCompanyftp,
                sch_cmp_ftp_path: this.examplePathOne,
                sch_reseller_ftp_credential_id: this.selectedResellerftp,
                sch_reseller_ftp_path: this.examplePathTwo,
            };
        },
        getToValue(value) {
            this.selectedTo = [];
            for (let i = 0; i < value.length; i++) {
                this.selectedTo.push(this.userProfiles[value[i]].usr_email);
            }
            this.selectedTo = this.selectedTo.join();
        },
        getCcValue(value) {
            this.selectedCc = [];
            for (let i = 0; i < value.length; i++) {
                this.selectedCc.push(this.userProfiles[value[i]].usr_email);
            }
            this.selectedCc = this.selectedCc.join();
        },
        getCompanyValue(value) {
            this.accountsOptions = [];
            this.selectedCompany = this.companies[value];
            this.$http.post("/user", this.accountsParams(), { headers: this.headers }).then(async (response) => {
                this.accountsResponse = JSON.parse(response.data.keyvalues[0].value);
                for (let i = 0; i < this.accountsResponse.length; i++) {
                    this.accountsOptions.push({
                        value: i,
                        text: this.accountsResponse[i].label,
                    });
                }
            });
            this.showAccounts = true;
        },
        accountsParams() {
            return {
                action: "getAccountTreeForReport",
                userId: this.userInfo.usrId,
                cmpId: this.selectedCompany.cmp_id,
                keyvalues: [{ key: "reportId", value: -1 }],
            };
        },
        getAccountsValue(value) {
            this.accountIds = [];
            this.selectedAccounts = [];
            this.accountsChildren = [];
            for (let i = 0; i < value.length; i++) {
                let account = this.accountsResponse[value[i]];
                this.getIds(account);
                for (let j = 0; j < this.accountIds.length; j++) {
                    this.accountsChildren.push(this.accountIds[j]);
                }
                this.accountIds = [];
            }
            this.accountsChildren = this.accountsChildren.join();
        },
        getIds(account) {
            if (account.id != null) {
                this.accountIds.push(account.id);
            } else {
                for (let i = 0; i < account.children.length; i++) {
                    this.getIds(account.children[i]);
                }
            }
        },
        toggleEnable(row) {
            if (row.sch_active_desc == "Inactive") {
                row.sch_active_desc = true;
            } else {
                row.sch_active_desc = false;
            }
            this.toggleId = row.sch_id;
            this.$httpReports
                .put("/scheduledreport/" + row.sch_id, this.toggleParams(row.sch_active_desc), {
                    headers: this.headers,
                })
                .then(async (response) => {
                    this.rowToggled = response;
                    await this.createTable();
                });
        },
        toggleParams(active) {
            return { sch_id: this.toggleId, sch_active: active, sch_type: 1 };
        },
        getFormatsValue(value) {
            this.selectedFormat = value;
        },
        getFrequenciesValue(value) {
            this.selectedFrequency = value;
        },
        getDeliveryDaysValue(value) {
            this.selectedDeliveryDay = value;
        },
        getDateRangeValue(value) {
            this.selectedReportDateRange = value;
        },
        getOwnerValue(value) {
            this.selectedReportOwner = this.userProfiles[value].usr_id;
        },
        getCompanyFTPValue(value) {
            this.selectedCompanyFTP = this.companyCredentials[value];
        },
        getResellerFTPValue(value) {
            this.selectedResellerFTP = this.resellerCredentials[value];
        },
        getReportValue(value) {
            for (let i = 0; i < this.generalReports.length; i++) {
                if (this.generalReports[i].name === value) {
                    this.selectedReport = this.generalReports[i];
                }
            }
            for (let i = 0; i < this.customReports.length; i++) {
                if (this.customReports[i].name === value) {
                    this.selectedReport = this.customReports[i];
                }
            }
            for (let i = 0; i < this.systemReports.length; i++) {
                if (this.systemReports[i].name === value) {
                    this.selectedReport = this.systemReports[i];
                }
            }
        },
        editReport(row) {
            this.showAccounts = true;
            this.newScheduleName = row.sch_name;
            this.editId = row.sch_id;
            this.selectedCc = row.sch_cc;
            this.selectedTo = row.sch_to;
            this.examplePathOne = row.sch_cmp_ftp_path;
            this.examplePathTwo = row.sch_reseller_ftp_path;
            for (let i = 0; i < this.reportsOptions.length; i++) {
                if (row.report_name == this.reportsOptions[i].text) {
                    this.reportsOptions[i].selected = true;
                    this.selectedReport = this.reportsOptions[i];
                }
            }
            for (let i = 0; i < this.formats.length; i++) {
                if (row.sch_output_type == this.formats[i].value) {
                    this.formats[i].selected = true;
                    this.selectedFormat = this.formats[i].value;
                }
            }
            for (let i = 0; i < this.frequencies.length; i++) {
                if (row.sch_freq_week == this.frequencies[i].value) {
                    this.frequencies[i].selected = true;
                    this.selectedFrequency = this.frequencies[i].value;
                }
            }
            for (let i = 0; i < this.deliveryDays.length; i++) {
                if (row.sch_freq_day == this.deliveryDays[i].value) {
                    this.deliveryDays[i].selected = true;
                    this.selectedDeliveryDay = this.deliveryDays[i].value;
                }
            }
            for (let i = 0; i < this.reportDateRanges.length; i++) {
                if (row.sch_timespan == this.reportDateRanges[i].value) {
                    this.reportDateRanges[i].selected = true;
                    this.selectedReportDateRange = this.reportDateRanges[i].value;
                }
            }
            for (let i = 0; i < this.companyOptions.length; i++) {
                if (row.sch_cmp_id == this.companyOptions[i].id) {
                    this.companyOptions[i].selected = true;
                    this.getCompanyValue(i);
                }
            }
            for (let i = 0; i < this.toOptions.length; i++) {
                if (row.sch_to == this.toOptions[i].email) {
                    this.toOptions[i].selected = true;
                }
            }
            for (let i = 0; i < this.ccOptions.length; i++) {
                if (row.sch_cc == this.ccOptions[i].email) {
                    this.ccOptions[i].selected = true;
                }
            }
            for (let i = 0; i < this.companyFTPOptions.length; i++) {
                if (row.sch_cmp_ftp_credential_id == this.companyFTPOptions[i].id) {
                    this.companyFTPOptions[i].selected = true;
                    this.selectedCompanyFTP = this.companyFTPOptions[i];
                }
            }
            for (let i = 0; i < this.resellerFTPOptions.length; i++) {
                if (row.sch_reseller_ftp_credential_id == this.resellerFTPOptions[i].id) {
                    this.resellerFTPOptions[i].selected = true;
                    this.selectedResellerFTP = this.resellerFTPOptions[i];
                }
            }
            this.editModal = true;
        },
        editSchedule() {
            this.verified = this.verifyFields();
            if (this.verified) {
                this.$httpReports
                    .put("/scheduledreport/" + this.editId, this.reportParams(), { headers: this.headers })
                    .then(async (response) => {
                        this.scheduleEdited = response;
                        await this.createTable();
                        this.editModal = false;
                    });
            }
        },
        deleteReport(row) {
            this.$httpReports
                .delete("/scheduledreport/" + row.sch_id, { headers: this.headers })
                .then(async (response) => {
                    this.scheduleDeleted = response;
                    await this.createTable();
                });
        },
        async searchTable(params) {
            this.searchTerm = params.searchTerm;
            await this.createTable();
        },
        onPageChange(params) {
            this.page = params.currentPage;
            this.createTable();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.size = params.currentPerPage;
            this.createTable();
        },
        onSortChange(params) {
            this.sortColumn = params[0].field;
            this.sortDir = params[0].type;
            this.createTable();
        },
    },
    async mounted() {
        this.getInit();
    },
};
</script>
